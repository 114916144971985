import { useMemo } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import Typography from '@mui/material/Typography';
// import IntroParagraph from '@organisms/IntroParagraph/IntroParagraph';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { LaunchesModel } from '@model/launchesModel';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import { getFieldLink } from '@utils/functions/getFieldLink';
import styles from './LaunchesParagraph.module.scss';

const Marquee = dynamic(() => import('react-fast-marquee'));
const Box = dynamic(() => import('@mui/material/Box'));
const Grid = dynamic(() => import('@mui/material/Grid'));
const CustomCard = dynamic(() => import('@atoms/CustomCard/CustomCard'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const LinkChildren = dynamic(() => import('@atoms/LinkChildren/LinkChildren'));
const BgDotsVector = dynamic(() => import('@atoms/CustomVectors/BgDotsVector'));
const FadeIn = dynamic(() => import('@atoms/FadeIn'));
const BackgroundTransitionBox = dynamic(() => import('@atoms/BackgroundTransitionBox/BackgroundTransitionBox'));
const IntroParagraph = dynamic(() => import('@organisms/IntroParagraph/IntroParagraph'));

const checkVisibility = (locale: string, field_language_visibility?: 'any' | null | string) =>
  !field_language_visibility || field_language_visibility === 'any' || field_language_visibility === locale;

const LaunchesParagraph = ({ fieldParagraph, node }: FieldParagraphProps) => {
  const { locale } = useRouter();
  const showContent = checkVisibility(locale, fieldParagraph?.field_language_visibility);

  const mockDataUrl = useMemo(
    () => fieldParagraph.mockDataUrl || '/data/two-launches.json',
    [fieldParagraph.mockDataUrl]
  );

  // const {
  //   mappedData: { data, isLoaded },
  // } = useMapper<any, LaunchesModel>({
  //   backendData: fieldParagraph,
  //   mockDataUrl,
  //   mockDataMapper: (setData, mockData) => {
  //     setData({
  //       ...mockData,
  //       backgroundTransition: fieldParagraph.backgroundTransition,
  //     });
  //   },
  //   realDataMapper: (setMapperData, backendData) => {
  //     return setMapperData({
  //       colorVariant: backendData.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
  //       backgroundTransition: backendData.behavior_settings?.style_options?.transition?.css_class || '',
  //       direction: backendData.behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
  //       backgroundText: backendData.field_marquee?.replace(/_([^_]*)_/g, '<strong>$1</strong>') || '',
  //       items: backendData.field_items.map((item) => ({
  //         ...getFieldLink(item.field_link),
  //         title: item.field_title,
  //         imageUrl: absoluteUrl(item.field_media?.field_media_image?.uri?.url),
  //         imageAlt: item.field_media?.field_media_image?.resourceIdObjMeta.alt,
  //       })),
  //       ctaVariant: 'secondary',
  //       cta: null,
  //       title: 'Test',
  //       description: 'Description',
  //       overline: 'Overline',
  //       anchorData: backendData.behavior_settings?.style_options?.toc?.label || '',
  //     });
  //   },
  // });

  const data = useMemo(() => {
    const newData: LaunchesModel = {
      colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
      backgroundTransition: fieldParagraph.behavior_settings?.style_options?.transition?.css_class || '',
      direction: fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
      backgroundText: fieldParagraph.field_marquee?.replace(/_([^_]*)_/g, '<strong>$1</strong>') || '',
      items: fieldParagraph.field_items.map((item) => ({
        ...getFieldLink(item.field_link),
        title: item.field_title,
        imageUrl: absoluteUrl(item.field_media?.field_media_image?.uri?.url),
        imageAlt: item.field_media?.field_media_image?.resourceIdObjMeta.alt,
      })),
      ctaVariant: 'secondary',
      cta: null,
      title: 'Test',
      description: 'Description',
      overline: 'Overline',
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
      dotsStyle: fieldParagraph.behavior_settings?.style_options?.bg_dots_styles?.css_class || '',
    };

    return newData;
  }, [fieldParagraph]);

  const lgColumnSize = useMemo(() => {
    if (!data) return 12;

    return 12 / data.items.length;
  }, [data]);

  if (!data) return null;
  if (!showContent) return null;

  return (
    <BackgroundTransitionBox
      anchor-data={transformTitleId(data.anchorData || '')}
      py={{ xs: 80, lg: 104 }}
      position="relative"
      linesDirection={data.direction}
      colorVariant={data.colorVariant}
      transition={data.backgroundTransition}
    >
      <FadeIn>
        <IntroParagraph
          removePadding={true}
          node={node}
          isBackgroundColorDisabled={true}
          isTransitionBoxDisabled={true}
          mockDataUrl={mockDataUrl}
          fieldParagraph={fieldParagraph}
        />

        <Box position="relative">
          {!!data.dotsStyle && (
            <BgDotsVector
              sxOverride={{
                position: 'absolute',
                top: { xs: theme.spacing(-39), lg: theme.spacing(-38) },
                ...(data.dotsStyle === 'bg-dots-left'
                  ? {
                      left: {
                        xs: theme.spacing(-162),
                        lg: 0,
                      },
                    }
                  : { right: { xs: theme.spacing(-162), lg: 0 } }),
              }}
            />
          )}
        </Box>

        <GridContainer>
          <Row mt={64} sx={{ gap: { xs: 16, lg: 'inherit' } }}>
            {data.items.map((item, index) => (
              <Grid key={index} item xs={12} lg={lgColumnSize}>
                <LinkChildren
                  className={styles.cardLaunches}
                  isExternal={item.isExternal}
                  url={item.url}
                  target={item.target}
                >
                  <CustomCard
                    variant="with-image-center-title-and-link"
                    title={item.title}
                    label={item.label}
                    imageAlt={item.imageAlt}
                    imageUrl={item.imageUrl}
                  />
                </LinkChildren>
              </Grid>
            ))}
          </Row>

          {!!data?.backgroundText && (
            <Box mt={theme.spacing(54)} pb={theme.spacing(170)}>
              <Marquee gradient={false}>
                <Typography
                  component="p"
                  fontWeight={300}
                  className={styles.BackgroundText}
                  fontSize={{ xs: theme.spacing(44), lg: theme.spacing(80) }}
                  lineHeight={theme.spacing(100)}
                  color="alphaWhite.20"
                  dangerouslySetInnerHTML={{ __html: data.backgroundText }}
                />
              </Marquee>
            </Box>
          )}
        </GridContainer>
      </FadeIn>
    </BackgroundTransitionBox>
  );
};

export default LaunchesParagraph;
